body{
    background-color: #F8F8F8 !important;
}
.btn{
    white-space: nowrap;
}
@media only screen and (max-width: 768px) {
    .main-content{
        left: 78px !important;
        width: calc(100% - 78px) !important;
    }
    .home-content .bx-menu{
        margin-left: 5px !important;
        margin-right: 0px !important;
    }
    .home-section {
        position: relative;
        background: #E4E9F7;
        left: 285px;
        width: calc(100% - 285px);
        transition: all 0.5s ease;
    }
    .sidebar{
        width: 285px;
    }
    .header{
        padding: 0px !important;
    }
    .card-body p{
        display: block !important;
    }
    .user{
        text-align: center;
    }
    .card-header{
        padding: 10px !important;
        display: block;
    }
    .card-body {
        padding: 10px;
    }
    .btn{
        white-space: nowrap;
        font-size: 14px;
    }
    .card-footer{
        padding: 10px !important;
    }
  }


  .comingsoonrent {
    margin: 0;
    padding: 0;
    height: 100%;
  }
  
  @keyframes gradientBackground {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  .containerdata {
    /* background: linear-gradient(-45deg, #36827f, #276c6a, #1e5150, #144d4a); */
    background-size: 400% 400%;
    animation: gradientBackground 15s ease infinite;
    height: 50vh;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  
  .containerdata .mainText {
    text-align: center;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }
  
  .containerdata .title {
    font-family: 'Roboto', sans-serif;
    font-size: 6em;
    margin-bottom: 0.2em;
    color: #051D34;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    transition: transform 0.3s;
  }
  
  .containerdata .title:hover {
    transform: scale(1.1);
  }
  
  .containerdata .subtitle {
    font-size: 2em;
    margin-bottom: 0.5em;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    transition: transform 0.3s;
  }
  
  .containerdata .subtitle:hover {
    transform: scale(1.1);
  }
  
  .containerdata .launchText {
    font-size: 1.2em;
    margin-bottom: 1em;
  }
  
  .containerdata .comingSoon {
    display: flex;
    justify-content: center;
  }
  
  .containerdata .comingSoonText {
    color:#051D34 ;
    font-size: 3em;
    margin: 0 0.2em;
    animation: comingSoonAnimation 1.5s infinite alternate;
  }
  
  @keyframes comingSoonAnimation {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-20px);
    }
  }
  
  
  
  @media (max-width: 768px) {
      .containerdata .title {
      font-size: 4em;
    }
    .containerdata .subtitle {
      font-size: 1.5em;
    }
    .containerdata .launchText {
      font-size: 1em;
    }
    .containerdata .comingSoonText {
      font-size: 2em;
    }
  }