.error {
  font-size: 11px;
  color: red;
  font-weight: 500;
}

.did-floating-select:not([value=""]):valid ~ .did-floating-label {
  top:-14px;
  font-size:13px;
}
.did-floating-select[value=""]:focus ~ .did-floating-label {
  top:11px;
  font-size:13px;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
  opacity: 1;
  -webkit-text-fill-color: #000 !important;
}

.App {
  text-align: center;
}

.MuiFormControl-root{
  width: 100%;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
