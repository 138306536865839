.main-tittle{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.btn-warning{
    background-color: #051D34 !important;
    box-shadow: none !important;
    border-color: transparent !important;
    outline: none !important;
    color: #fff !important;
}
.btn-warning:hover{
    background-color: #051D34;
}
.btn-secess:hover{
    background-color: #051D34 !important;
}
.btn-secess{
    background-color: #051D34;
    box-shadow: none !important;
    border-color: transparent !important;
    outline: none !important;
    color: #fff !important;
}
input[type=date]::-webkit-datetime-edit-text {
    -webkit-appearance: none;
    display: none;
}
input[type=date]::-webkit-datetime-edit-month-field{
    -webkit-appearance: none;
    display: none;
}
input[type=date]::-webkit-datetime-edit-day-field {
    -webkit-appearance: none;
    display: none;
}
input[type=date]::-webkit-datetime-edit-year-field {
    -webkit-appearance: none;
    display: none;
}
.table-green-label{
    background-color: #d2f7d1 !important;
    color: #68e365 !important;
    border-color: transparent !important;
}
.action-btns svg{
    font-size: 22px;
    cursor: pointer;
}
.table-red-label{
    background-color: #fee6ea !important;
    color: #f72b50 !important;
    border-color: transparent !important;
}

.close-icon{
    color: #f72b50 !important;
    font-size: 80px;
}
.edit-btn{
    margin: 0 4px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: #e7fbe6;
    border-color: #e7fbe6;
    color: #68e365;
    display: flex;
    align-items: center;
    justify-content: center;
}
.view-btn{
    margin: 0 4px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: #d9f6f3;
    border-color: #d9f6f3;
    color: #145650;
    display: flex;
    align-items: center;
    justify-content: center; 
}
.delete-btn{
    margin: 0 4px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: #fee6ea;
    border-color: #fee6ea;
    color: #f72b50;
    display: flex;
    align-items: center;
    justify-content: center; 
}