.login-main{
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.login {
    margin: 20px auto;
    padding: 40px 50px;
    width: 450px;
    border-radius: 5px;
    background: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
    .login input {
      width: 100%;
      display: block;
      box-sizing: border-box;
      margin: 10px 0;
      padding: 14px 12px;
      font-size: 16px;
      border-radius: 2px; 
      font-family: Raleway, sans-serif;
    }
  
  .login input[type=text],
  .login input[type=password] {
    border: 1px solid #c0c0c0;
    transition: .2s;
  }
  
  .login input[type=text]:hover {
    border-color: #051D34;
    outline: none;
    transition: all .2s ease-in-out;
  } 
  
  .login input[type=submit] {
    border: none;
    background: #051D34;
    color: white;
    font-weight: bold;  
    transition: 0.2s;
    margin: 20px 0px;
  }
  
  .login input[type=submit]:hover {
    background: #051D34;  
  }
  
    .login h2 {
      margin: 20px 0 0; 
      color: #051D34;
      font-size: 28px;
    }
  
  .login p {
    margin-bottom: 40px;
  }
  
  .links {
    display: table;
    width: 100%;  
    box-sizing: border-box;
    border-top: 1px solid #c0c0c0;
    margin-bottom: 10px;
  }
  
  .links a {
    display: table-cell;
    padding-top: 10px;
  }
  
  .links a:first-child {
    text-align: left;
  }
  
  .links a:last-child {
    text-align: right;
  }
  
    .login h2,
    .login p,
    .login a {
      text-align: center;    
    }
  
  .login a {
    text-decoration: none;  
    font-size: .8em;
  }
  
  .login a:visited {
    color: inherit;
  }
  
  .login a:hover {
    text-decoration: underline;
  }

  @media only screen and (max-width: 600px) {
    .login {
      width: 90%;
    }
  }